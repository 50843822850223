import _ from 'lodash'
import React, { ReactNode } from 'react'
import { OrderValueRecord, ProductValueRecord } from '../../services/RefundCalculatorService'
import { toDateTime } from '../../utils'

interface Props {
  orderValue: OrderValueRecord,
  title?: string,
  children?: ReactNode
}

export default class OrderValueCard extends React.Component<Props> {
  render() {
    const orderValue = this.props.orderValue

    const orderedProducts = [...orderValue.productValueRecords]
    orderedProducts.sort((a: ProductValueRecord, b: ProductValueRecord) => a.productCode.localeCompare(b.productCode))

    const totalValue = _.sum(orderedProducts.map((x) => x.value)) + orderValue.shippingCostValue

    return (
      <>
        <div className='order-value-record'>
          <table className="bo-grid-vertical wide">
            <tbody>
              <tr>
                <th>{this.props.title ?? ''}</th>
                <td>{orderValue.orderCode}</td>
              </tr>
              <tr>
                <th>Total value</th>
                <td>{concatCurrency(totalValue, orderValue.currency)}</td>
              </tr>
              <tr>
                <th>Date</th>
                <td>{toDateTime(orderValue.date)}</td>
              </tr>
              {orderValue.reason &&
                <tr>
                  <th>Reason</th>
                  <td>{orderValue.reason}</td>
                </tr>
              }
              {renderHorizontalLine()}
              {orderedProducts.map(x => (
                <tr key={x.productCode}>
                  <th>
                    <div className='d-flex product-code'>
                      <div>{x.productCode}</div>
                      <div className='ml-2 order-value-record-quantity'>{`(qty:${x.quantity})`}</div>
                    </div>
                  </th>
                  <td>{concatCurrency(x.value, orderValue.currency)}</td>
                </tr>
              ))}
              <tr>
                <th>ShippingCosts</th>
                <td>{concatCurrency(orderValue.shippingCostValue, orderValue.currency)}</td>
              </tr>
            </tbody>
          </table>
          {this.props.children &&
            <div className='d-flex flex-row-reverse'>
              {this.props.children}
            </div>
          }
        </div>
      </>
    )
  }
}

function concatCurrency(value?: number, currency?: string) {
  if (value !== undefined) {
    const currencyFormattedNumber = (Math.round(value * 100) / 100).toFixed(2);
    return currency !== undefined ? `${currencyFormattedNumber} ${currency}` : value
  }
  return undefined
}

function renderHorizontalLine() {
  return (
    <tr className="delimiter">
      <th colSpan={2}>
        <hr />
      </th>
    </tr>
  )
}

