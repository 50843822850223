import _ from 'lodash'
import { OrderDetails, Price } from '../api'
import { ProductWithValue } from './OrderManagementService'

export interface ProductValueRecord {
  productCode: string,
  quantity: number,
  value: number
}

export type OrderValueRecordType = 'Base' | 'Discount' | 'Remainder' | 'Refund'

export interface OrderValueRecord {
  orderCode: string,
  productValueRecords: ProductValueRecord[]
  shippingCostValue: number,
  recordType: OrderValueRecordType,
  reason?: string,
  currency: string,
  date: Date
}

export class RefundCalculatorService {

  getBaseValueRecord(orderDetails: OrderDetails): OrderValueRecord {
    const orderValueRecord = {
      orderCode: orderDetails.id,
      productValueRecords: orderDetails.products.map((p) => {
        return {
          productCode: p.id,
          quantity: p.quantity,
          value: p.totalPrice ?? 0
        }
      }),
      shippingCostValue: orderDetails.price?.shipment ?? 0,
      recordType: 'Base',
      currency: this.getCurrencyFromPrice(orderDetails.price),
      date: orderDetails.createdDateTime ?? new Date(Date.now())
    } as OrderValueRecord
    return orderValueRecord
  }

  getDiscountValueRecord(orderDetails: OrderDetails): OrderValueRecord {
    const orderValueRecord = {
      orderCode: orderDetails.id,
      productValueRecords: orderDetails.products.map((p) => {
        return {
          productCode: p.id,
          quantity: p.quantity,
          value: Math.abs(p.totalDiscount ?? 0)
        }
      }),
      shippingCostValue: Math.abs(Number(orderDetails.price?.shippingDiscount ?? 0)),
      recordType: 'Discount',
      currency: this.getCurrencyFromPrice(orderDetails.price),
      date: orderDetails.createdDateTime ?? new Date(Date.now())
    } as OrderValueRecord
    return orderValueRecord
  }

  getCurrencyFromPrice(price?: Price): string {
    return price?.currency ?? "???";
  }

  subtractOrderValueRecord(minuend: OrderValueRecord, substrahend: OrderValueRecord) {
    const result = {
      ...minuend,
      productValueRecords: minuend.productValueRecords.map(mp => {
        const thing = {
          ...mp,
          value: Math.round((mp.value - (substrahend.productValueRecords.find(sp => sp.productCode === mp.productCode)?.value ?? 0)) * 100) / 100
        } as ProductValueRecord
        return thing
      }),
      shippingCostValue: Math.round((minuend.shippingCostValue - substrahend.shippingCostValue) * 100) / 100
    } as OrderValueRecord
    return result
  }

  calculateShippingCostRefund(remainingAmountRecord: OrderValueRecord): OrderValueRecord {
    return {
      ...remainingAmountRecord,
      productValueRecords: [],
      recordType: "Refund",
      date: new Date(Date.now())
    };
  }

  calculatePercentageRefund(percentage: number, includeShippingCosts: boolean, products: ProductWithValue[], remainingAmountRecord: OrderValueRecord): OrderValueRecord {
    const productRefundRecords = products.filter((p) => p.value > 0).map((p) => {
      const productValueRecord = remainingAmountRecord.productValueRecords.find((pvr) => pvr.productCode === p.id)

      if (productValueRecord) {

        const percentageMultiplier = percentage / 100
        const selectedQuantityFraction = p.value / p.quantity
        const productRefund = percentageMultiplier * (selectedQuantityFraction * productValueRecord.value)
        return { value: Math.round(productRefund * 100) / 100, quantity: p.quantity, productCode: p.id } as ProductValueRecord
      }

      return { value: 0, quantity: p.quantity, productCode: p.id } as ProductValueRecord
    })

    return {
      ...remainingAmountRecord,
      shippingCostValue: includeShippingCosts ? Math.round((percentage / 100) * remainingAmountRecord.shippingCostValue * 100) / 100 : 0,
      productValueRecords: productRefundRecords,
      recordType: "Refund",
      date: new Date(Date.now())
    }
  }

  calculateProductAmountRefund
    (amount: number, products: ProductWithValue[], includeShippingCosts: boolean, calculatePerCopy: boolean, remainingAmountRecord: OrderValueRecord)
    : OrderValueRecord {
    return {
      orderCode: remainingAmountRecord.orderCode,
      shippingCostValue: includeShippingCosts ? remainingAmountRecord.shippingCostValue : 0,
      recordType: "Refund",
      date: new Date(Date.now()),
      currency: remainingAmountRecord.currency,
      productValueRecords: products.filter((p) => p.value > 0)
        .map((p) => {
          return {
            productCode: p.id,
            quantity: p.value,
            value: calculatePerCopy ? amount * p.value : amount
          } as ProductValueRecord
        })
    } as OrderValueRecord
  }

  calculateAmountRefund(amount: number, remainingAmountRecord: OrderValueRecord): OrderValueRecord {
    const amountToRefundPerProductItem = amount / _.sum(remainingAmountRecord.productValueRecords.map((p) => p.quantity));

    return {
      orderCode: remainingAmountRecord.orderCode,
      shippingCostValue: 0,
      recordType: "Refund",
      date: new Date(Date.now()),
      currency: remainingAmountRecord.currency,
      productValueRecords: remainingAmountRecord.productValueRecords
        .map((p) => {
          return {
            productCode: p.productCode,
            quantity: p.quantity,
            value: Math.round(p.quantity * amountToRefundPerProductItem * 100) / 100
          } as ProductValueRecord
        })
    } as OrderValueRecord
  }
}

export const RefundCalculatorServiceInstance: RefundCalculatorService = new RefundCalculatorService()

