import _ from 'lodash'
import { OrderDetails } from '../api'
import { ProductWithValue } from './OrderManagementService'
import type { Price } from '../api/order'
import type { Product } from '../api/product'

export class OldRefundCalculatorService {

  calculateRefund(percentage: number, includeShippingCosts: boolean, products: ProductWithValue[], orderDetails: OrderDetails): number {

    let refundAmount: number = 0;

    if (products.length > 0) {
      refundAmount = _.sum(products.map((p) => {
        if (p.totalDiscount !== undefined && p.totalDiscount !== 0) {
          const totalDiscountPerProduct = Math.abs(p.totalDiscount) / p.quantity
          const totalPricePerProduct = Number(p.totalPricePerItem) - totalDiscountPerProduct
          return p.value * totalPricePerProduct
        }
        else {
          return p.value * Number(p.totalPricePerItem)
        }
      }))
      if (includeShippingCosts) {
        const { shipment, discount, discountCode } = orderDetails.price as Price
        const shippingDiscount = this.tryGetShippingDiscount(products, discount, discountCode)
        const isShippingDiscountExist = shippingDiscount > 0
        const totalOrderProductsQuantity = _.sum(products.map((p) => p.quantity))
        const averageShippingCostsPerProduct = (isShippingDiscountExist ? Number(shipment) - shippingDiscount : Number(shipment)) / totalOrderProductsQuantity
        const totalSelectedProducts = _.sum(products.map((p) => p.value))
        const selectedProductsShippingCosts = averageShippingCostsPerProduct * totalSelectedProducts

        refundAmount += selectedProductsShippingCosts
      }
    } else if (includeShippingCosts) {
      const { shipment, discount, discountCode } = orderDetails.price as Price
      const shippingDiscount = this.tryGetShippingDiscount(orderDetails.products, discount, discountCode)
      const isShippingDiscountExist = shippingDiscount > 0
      refundAmount = isShippingDiscountExist ? Number(shipment) - shippingDiscount : Number(shipment)
    }

    return (percentage / 100) * refundAmount;
  }

  tryGetShippingDiscount(products: Product[], totalDiscount?: string, discountCode?: string) {
    const isDiscountCodeApplied = Number(discountCode?.trim().length) > 0

    if (!isDiscountCodeApplied) {
      return -1;
    }

    const orderTotalDiscount = Number(totalDiscount) * (-1)
    const productsTotalDiscount = _.sum(products.map((p) => p.totalDiscount)) * (-1)

    return orderTotalDiscount - productsTotalDiscount
  }

}

export const OldRefundCalculatorServiceInstance: OldRefundCalculatorService = new OldRefundCalculatorService()