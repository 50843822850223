import _ from 'lodash'
import React, { ChangeEvent } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import { validators } from '../../utils'
import { OrderValueRecord } from '../../services/RefundCalculatorService'
import { RefundCalculatorServiceInstance as refundCalculatorService } from '../../services/RefundCalculatorService'
import { OperationType, ProductWithValue } from '../../services/OrderManagementService'
import OrderStructure from './OrderStructure'
import { OrderDetails } from '../../api'
import Checkbox from './Checkbox'

interface State {
  amount: number
  products: ProductWithValue[],
  includeShippingCosts: boolean,
  calculatePerCopy: boolean
}

interface Props {
  onChangeRefund(orderRefund: OrderValueRecord): void
  remainingRefundRecord: OrderValueRecord,
  orderDetails: OrderDetails
}

export default class ProductAmountRefundForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { amount: 0, products: [], includeShippingCosts: false, calculatePerCopy: false }
    this.handleAmountChange = this.handleAmountChange.bind(this)
    this.handleProductsChange = this.handleProductsChange.bind(this)
    this.handleShippingCosts = this.handleShippingCosts.bind(this)
    this.handleRadioButtonToggle = this.handleRadioButtonToggle.bind(this)
    this.validateAmount = this.validateAmount.bind(this)
  }

  render() {
    const { amount, includeShippingCosts } = this.state
    const amountErrorMessage = this.validateAmount()

    return (
      <>
        <FormGroup>
          <Input
            invalid={amount !== undefined && !!amountErrorMessage}
            onChange={this.handleAmountChange}
            id="refund-amount-input"
          />
          <FormFeedback>{amountErrorMessage}</FormFeedback>
        </FormGroup>
        <div>
          <FormGroup check inline>
            <Label>
              <Input
                type="radio"
                name="calculatePerProduct"
                onChange={this.handleRadioButtonToggle}
                checked={!this.state.calculatePerCopy}
              />
              Refund per Product
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label>
              <Input
                type="radio"
                name="calculatePerCopy"
                onChange={this.handleRadioButtonToggle}
                checked={this.state.calculatePerCopy}
              />
              Refund per Product Copy
            </Label>
          </FormGroup>
        </div>
        <OrderStructure
          data={this.props.orderDetails}
          handleChange={this.handleProductsChange}
          operation={OperationType.RefundAmount}
        />
        <FormGroup>
          <Checkbox id="includeShippingCosts" checked={includeShippingCosts} onChange={this.handleShippingCosts} />
          <Label for="includeShippingCosts" className="ml-4">
            Include shipping costs
          </Label>
        </FormGroup>
      </>
    )
  }

  private validateAmount() {
    var amountErrorMessage = validators.currency(this.state.amount)
    if (!amountErrorMessage) {
      const maximumAllowedAmount = _.min(this.props.remainingRefundRecord.productValueRecords
        //only include products that have more than 0 copies selected
        .filter((pvr) => this.state.products.find((p) => p.id === pvr.productCode)?.value ?? 0 > 0)
        //calculate value either per copy or for full product
        .map((pvr) => (this.state.calculatePerCopy) ? pvr.value / pvr.quantity : pvr.value))
      if (maximumAllowedAmount && this.state.amount > maximumAllowedAmount) {
        const formattedMax = (Math.round(maximumAllowedAmount * 100) / 100).toFixed(2)
        amountErrorMessage = `Can not be more than ${formattedMax} per ${(this.state.calculatePerCopy) ? 'copy' : 'product'} with these selected products`
      }
    }
    return amountErrorMessage
  }

  private handleAmountChange(event: ChangeEvent<{ value: string }>) {
    this.setState(
      {
        amount: Number(event.target.value)
      },
      () => this.calculateRefund()
    )
  }

  private handleProductsChange(products: Array<ProductWithValue>) {
    this.setState(
      {
        products: products,
      },
      () => this.calculateRefund()
    )
  }

  private handleShippingCosts(event: any) {
    this.setState(
      {
        includeShippingCosts: event.target.checked,
      },
      () => this.calculateRefund()
    )
  }

  handleRadioButtonToggle(event: any) {
    this.setState(
      {
        calculatePerCopy: event.target.name === 'calculatePerCopy',
      },
      () => this.calculateRefund()
    )
  }


  private calculateRefund() {
    const amountErrorMessage = this.validateAmount()

    const refundRecord = refundCalculatorService.calculateProductAmountRefund(
      //Give no refund if the data is invalid
      (amountErrorMessage) ? 0 : this.state.amount,
      this.state.products,
      this.state.includeShippingCosts,
      this.state.calculatePerCopy,
      this.props.remainingRefundRecord
    ) as OrderValueRecord
    refundRecord.recordType = 'Refund'
    this.props.onChangeRefund(refundRecord)
  }
}
