import React from 'react'
import { CoverColorNames, DataSource, OrderDetails, Price, Product, ProductExtra } from '../../api'
import routes from '../../route/routes'
import { OrderDetailsServiceInstance as Service } from '../../services/OrderDetailsService'
import { fromCamelCase } from '../../utils'
import ProductLabel from '../forms/ProductLabel'
import ActionLink from '../generic/ActionLink'

enum ExtraTypes {
  Border = 'Border',
  CoverText = 'CoverText',
  CoverType = 'CoverType',
  ExtraPages = 'ExtraPages',
  FloatingFrame = 'FloatingFrame',
  Glossy = 'Glossy',
  ImageEnhancement = 'ImageEnhancement',
  LayFlat = 'LayFlat',
  PaperType = 'PaperType',
  PremiumLayFlat = 'PremiumLayFlat',
  PrintSize = 'PrintSize',
  VoucherDiscount = 'VoucherDiscount',
  Wrappers = 'Wrappers'
}

interface Props {
  order: OrderDetails
  showRefundButton?: boolean
}

function renderHorizontalLine() {
  return (
    <tr className="delimiter">
      <th colSpan={2}>
        <hr />
      </th>
    </tr>
  )
}

function renderOrderLevel(price: Price) {
  return (
    <React.Fragment>
      <tr>
        <th>Total order</th>
        <td>{concatCurrency(price.total, price.currency)}</td>
      </tr>
      {renderHorizontalLine()}
      <tr>
        <th>Sub Total</th>
        <td>{concatCurrency(price.subTotal, price.currency)}</td>
      </tr>
      <tr>
        <th>Total discount</th>
        <td>{concatCurrency(price.discount, price.currency)}</td>
      </tr>
      <tr>
        <th>Shipment</th>
        <td>{concatCurrency(price.shipment, price.currency)}</td>
      </tr>
    </React.Fragment>
  )
}

function getExtra(type: ExtraTypes, allExtras: ProductExtra[]) {
  return (allExtras && allExtras.filter(e => e.name === type && e.total !== undefined)) || []
}

function renderTotal(type: ExtraTypes, allExtras: ProductExtra[], currency?: string) {
  const [extra] = getExtra(type, allExtras)
  return (
    extra && (
      <tr>
        <th>{fromCamelCase(type)} Total</th>
        <td>{concatCurrency(extra.total, currency)}</td>
      </tr>
    )
  )
}

function renderOptionCode(type: ExtraTypes, allExtras: ProductExtra[]) {
  const [extra] = getExtra(type, allExtras)
  return (
    extra &&
    extra.optionCode && (
      <tr>
        <th>{fromCamelCase(type)}</th>
        <td>{extra.optionCode}</td>
      </tr>
    )
  )
}

function renderQuantity(type: ExtraTypes, allExtras: ProductExtra[]) {
  const [extra] = getExtra(type, allExtras)
  return (
    extra &&
    extra.quantity !== undefined && (
      <tr>
        <th>{fromCamelCase(type)} Qty</th>
        <td>{extra.quantity}</td>
      </tr>
    )
  )
}

function renderProductLevel(product: Product, orderSource: DataSource, currency?: string) {
  const [coverType] = getExtra(ExtraTypes.CoverType, product.extras)
  const [extraPages] = getExtra(ExtraTypes.ExtraPages, product.extras)
  const printSizes = getExtra(ExtraTypes.PrintSize, product.extras)
  const productType = orderSource === DataSource.Studenten ? Service.resolveStudentenProductDescriptionByType(product.productId) : Service.resolveProductDescriptionByType(product.productId)

  return (
    <React.Fragment key={product.id}>
      <tr>
        <th>Product</th>
        <td>
          <ProductLabel
            productNumber={orderSource === DataSource.Studenten ? '' : product.id}
            productTitle={product.title}
            productType={productType}
            hidePreviewImage={true}
          />
        </td>
      </tr>
      <tr>
        <th>Sub Total</th>
        <td>{concatCurrency(product.totalPrice, currency)}</td>
      </tr>
      <tr>
        <th>Qty</th>
        <td>{product.quantity}</td>
      </tr>
      <tr>
        <th>Base item price</th>
        <td>{concatCurrency(product.basePricePerItem, currency)}</td>
      </tr>
      {extraPages && extraPages.quantity !== undefined && (
        <tr>
          <th>Extra Pages Qty</th>
          {/* quantity for extra pages should be multiplied by 2 */}
          <td>{extraPages.quantity * 2}</td>
        </tr>
      )}
      {renderTotal(ExtraTypes.ExtraPages, product.extras, currency)}
      {printSizes &&
        printSizes.map((extra, i) => (
          <React.Fragment key={i}>
            <tr>
              <th>Print Size</th>
              <td>
                {extra.optionCode}, Qty: {extra.quantity}
              </td>
            </tr>
            <tr>
              <th>Print Size Total</th>
              <td>{concatCurrency(extra.total, currency)}</td>
            </tr>
          </React.Fragment>
        ))}
      {renderOptionCode(ExtraTypes.PaperType, product.extras)}
      {renderTotal(ExtraTypes.PaperType, product.extras, currency)}
      {renderTotal(ExtraTypes.Glossy, product.extras, currency)}
      {renderTotal(ExtraTypes.CoverText, product.extras, currency)}
      {coverType && coverType.optionCode && (
        <tr>
          <th>Cover Color</th>
          <td>{CoverColorNames[coverType.optionCode] || coverType.optionCode}</td>
        </tr>
      )}
      {renderTotal(ExtraTypes.CoverType, product.extras, currency)}
      {renderOptionCode(ExtraTypes.VoucherDiscount, product.extras)}
      {renderTotal(ExtraTypes.VoucherDiscount, product.extras, currency)}
      {renderTotal(ExtraTypes.PremiumLayFlat, product.extras, currency)}
      {renderTotal(ExtraTypes.FloatingFrame, product.extras, currency)}
      {renderQuantity(ExtraTypes.Border, product.extras)}
      {renderTotal(ExtraTypes.Border, product.extras, currency)}
      {renderQuantity(ExtraTypes.ImageEnhancement, product.extras)}
      {renderTotal(ExtraTypes.ImageEnhancement, product.extras, currency)}
      <tr>
        <th>Discount</th>
        <td>{concatCurrency(product.generalDiscount, currency)}</td>
      </tr>
      <tr>
        <th>Bulk discount</th>
        <td>{concatCurrency(product.bulkDiscount, currency)}</td>
      </tr>
      <tr>
        <th>Total discount</th>
        <td>{concatCurrency(product.totalDiscount, currency)}</td>
      </tr>
      {renderHorizontalLine()}
    </React.Fragment>
  )
}

function concatCurrency(value?: string | number, currency?: string) {
  if (value !== undefined) {
    const currencyFormattedNumber = (Math.round(Number(value) * 100) / 100).toFixed(2);
    return currency !== undefined ? `${currencyFormattedNumber} ${currency}` : value
  }
  return undefined
}

export default function OrderPrices(props: Props) {
  if (!props.order.price) {
    return (
      <h3>
        <i>Price details are not available</i>
      </h3>
    )
  }

  return (
    <div className="order-price">
      {props.showRefundButton && (
        <ActionLink className="refunds-button medium" route={routes.OLDREFUNDS({ orderId: props.order.id })} text="Refund" />
      )}
      <table className="bo-grid-vertical wide">
        <tbody>
          {renderOrderLevel(props.order.price)}
          {renderHorizontalLine()}
          {props.order.products && props.order.products.map(p => renderProductLevel(p, props.order.source, props.order.price?.currency))}
        </tbody>
      </table>
    </div>
  )
}
