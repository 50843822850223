import _ from 'lodash'
import { ReactNode } from 'react'
import { OrderDetails, OrderLineStatus, Product } from '../api'
import { OrderDetailsServiceInstance as Service } from './OrderDetailsService'
import { LocalStorageInstance } from './LocalStorageService'
import { ProductionOrder } from '../api/productionOrder'

export enum OperationType {
  Reset,
  Cancel,
  Sbs,
  Note,
  RefundAmount,
  RefundPercentage,
  RefundOrder,
  RefundShippingCosts
}

export interface ProductWithValue extends Product {
  value: number
  disabled?: boolean
  tooltip?: string
  maxValue?: number
  isOrderLineHidden: boolean
  isOrderLineDisabled: boolean
  isCheckboxHidden: boolean
  groupId?: string
}

export interface AlertInfo {
  color: string
  content: ReactNode
}

export interface ProductionOrderDetails {
  productionOrderId: string
  disabled: boolean
  products: ProductWithValue[]
  status: string
}

const cancellationOfCancelledProductWarning = 'Cancellation of cancelled products is not allowed.'
const cancellationOfShippedProductWarning = 'Cancellation of shipped products is not allowed.'
const cancellationOfShippedOrCancelledProductWarning = 'Cancellation of a cancelled or shipped order is not allowed.'

class OrderManagementService {
  getProductsWithValue(order: OrderDetails, operation: OperationType): ProductWithValue[] {
    switch (operation) {
      case OperationType.Sbs:
        return this.getProductsWithValueForSbs(order)

      case OperationType.Reset:
        return this.getProductsWithValueForReset(order)

      case OperationType.Cancel:
        return this.getProductsWithValueForCancel(order)

      case OperationType.RefundAmount:
      case OperationType.RefundPercentage:
      case OperationType.RefundOrder:
      case OperationType.RefundShippingCosts:
        return _(order.products)
          .map((product) => ({
            ...product,
            value: 0,
            isOrderLineHidden: false,
            isOrderLineDisabled: false,
            isCheckboxHidden: false,
          }))
          .value()

      case OperationType.Note:
      default:
        return _(order.products)
          .map((product) => ({
            ...product,
            value: 0,
            isOrderLineHidden: true,
            isOrderLineDisabled: true,
            isCheckboxHidden: false,
          }))
          .value()
    }
  }

  getProductionOrders(order: OrderDetails, products: ProductWithValue[], operation: OperationType): ProductionOrderDetails[] {
    return order.productionOrders.map((po) => {
      const productCodes = po.shipments.map(s => s.products).reduce((result, nextProducts) => result.concat(nextProducts), [])

      const productionOrderProducts = po.shipments
        .filter(s => s.productsData !== undefined)
        .map(s => s.productsData)
        .reduce((result, nextProducts) => result.concat(nextProducts), [])

      return {
        productionOrderId: po.id,
        status: po.status,
        products: products.filter(p => productCodes.includes(p.id))
          .map(p => {
            if (productionOrderProducts.length !== 0) {
              const matchingProduct = productionOrderProducts.find(poProduct => poProduct.id === p.id)
              return { ...p, quantity: matchingProduct?.quantity }
            }
            return { ...p }
          }),
        disabled: this.isDisabled(po, operation)
      } as ProductionOrderDetails
    })
  }

  isDisabled(productionOrder: ProductionOrder, operation: OperationType): boolean {
    switch (operation) {
      case OperationType.Cancel:
        return productionOrder.status === 'Cancelled' || productionOrder.status === 'Shipped'
      case OperationType.Reset:
        return false
      default:
        return false
    }
  }

  getWarnings(products: ProductWithValue[]): AlertInfo[] {
    return _(products)
      .filter((p) => !!p.tooltip)
      .map((p) => p.tooltip)
      .uniq()
      .sort()
      .map((tooltip) => ({
        color: 'warning',
        content: tooltip,
      }))
      .value()
  }

  private getProductsWithValueForSbs(order: OrderDetails): ProductWithValue[] {
    return _(order.products)
      .map((product) => {
        let disabled = false
        let tooltip: string | undefined = undefined

        const ffProduct = Service.resolveFulfillmentProduct(product, order.fulfillment)
        const [plant] = Service.getPlants(ffProduct && ffProduct.lines)

        const config = LocalStorageInstance.getGlobalConfiguration()

        if (config?.plantFeatures.sbsDisabled.includes(plant.plant)) {
          disabled = true
          tooltip = `SBS not available for ${plant.plant} plant.`
        }
        return {
          ...product,
          value: 0,
          isOrderLineHidden: true,
          disabled,
          tooltip,
        } as ProductWithValue
      })
      .value()
  }

  private getProductsWithValueForReset(order: OrderDetails): ProductWithValue[] {
    return _(order.products)
      .map((product) => {
        let disabled: boolean | undefined = false
        let tooltip: string | undefined = undefined

        if (!product.pdfUrl && !product.albxUrl && !product.imageArchiveUrl) {
          disabled = true
          tooltip = 'Reset not possible: product file is not available.'
        }

        // TODO: Take this into account during Production Orders Reupload implementation
        if (Service.resolveProductReuploadState(order.orderEvents, product.id) !== undefined) {
          disabled = true
          tooltip = 'Reset not possible: conflicts with Reupload'
        }

        return {
          ...product,
          value: 0,
          disabled,
          tooltip,
          isOrderLineHidden: false,
          maxValue: undefined,
          isOrderLineDisabled: false,
          isCheckboxHidden: false,
          groupId: undefined,
        } as ProductWithValue
      })
      .value()
  }

  private getProductsWithValueForCancel(order: OrderDetails): ProductWithValue[] {
    return _(order.products)
      .map((product) => {
        let tooltip: string | undefined = undefined
        const cancelledQty = product.lines.filter((x) => x.status === OrderLineStatus.Cancelled).length
        const shippedQty = product.lines.filter((x) => x.status === OrderLineStatus.ScannedForShipment).length
        const ffProduct = Service.resolveFulfillmentProduct(product, order.fulfillment)
        const [plant] = Service.getPlantsFromOrderEvents(ffProduct && ffProduct.lines, order.orderEvents, ffProduct?.productCode)

        const config = LocalStorageInstance.getGlobalConfiguration()
        // No partial cancelations will be available for Production Orders
        const isPartialCancellationDisabled = config?.plantFeatures.sbsDisabled.includes(plant.plant)
        // The same product might be split into two different production orders,
        // but in this particular case we need to know if there's at least one Production Order associated with this Product.
        // This info will be used to show/hide some of the controls (see isCheckboxHidden and isOrderLineHidden)
        const matchingProductionOrder = order.productionOrders.find((po) =>
          po.shipments.some(s => s.products.some((p) => p == product.id))
        )

        const maxValue = Math.max(product.quantity, 0)
        const disabled = maxValue === 0
        if (shippedQty > 0 && cancelledQty > 0) {
          tooltip = cancellationOfShippedOrCancelledProductWarning
        } else if (cancelledQty > 0) {
          tooltip = cancellationOfCancelledProductWarning
        } else if (shippedQty > 0) {
          tooltip = cancellationOfShippedProductWarning
        } else if (isPartialCancellationDisabled) {
          tooltip =
            `It's not possible to do partial cancellation for products produced in ${plant.plant}. ` +
            `You can only cancel all ${plant.plant} products at once.`
        }

        const isOrderLineHidden = matchingProductionOrder !== undefined ? false : isPartialCancellationDisabled ? true : false
        const isOrderLineDisabled = matchingProductionOrder !== undefined ? true : false

        return {
          ...product,
          tooltip,
          disabled,
          maxValue,
          value: 0,
          isOrderLineHidden: isOrderLineHidden,
          isOrderLineDisabled: isOrderLineDisabled,
          isCheckboxHidden: matchingProductionOrder !== undefined ? true : false,
          // No partial cancelations will be available for Production Orders, so groupId logic should be removed eventually
          groupId: isPartialCancellationDisabled ? plant.plant : undefined
        } as ProductWithValue
      })
      .value()
  }
}

export const OrderManagementServiceInstance = new OrderManagementService()
