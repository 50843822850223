import React, { FormEvent } from 'react'
import { Col, Form, FormGroup, Row } from 'reactstrap'
import { formatCurrency } from '../../utils'
import Confirmation from './Confirmation'
import { OldRefundCalculatorServiceInstance as refundCalculatorService } from '../../services/OldRefundCalculatorService'
import * as OrderTypes from '../../api/order'
import { OperationType } from '../../services/OrderManagementService'

export interface FormData {
    amount?: number
    operationType?: OperationType
}

interface ShippingCostsRefundFormState {
    data: FormData
    showConfirmation: boolean
    error: string | null
}

interface Props {
    onSubmit(form: FormData): void
    valid: boolean
    orderDetails: OrderTypes.OrderDetails
}

export default class OldShippingCostsRefundForm extends React.Component<Props, ShippingCostsRefundFormState> {
    constructor(props: Props) {
        super(props)

        this.state = {
            data: {},
            showConfirmation: false,
            error: '',
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toggleConfirmation = this.toggleConfirmation.bind(this)
    }

    componentDidMount() {
        this.setState(state => (state.data.operationType = OperationType.RefundShippingCosts, state))
        this.calculateRefund()
    }

    render() {
        const { data, showConfirmation } = this.state
        const submitDisabled = !this.props.valid

        return (
            <Form onSubmit={this.handleSubmit}>
                <Confirmation
                    isOpen={showConfirmation}
                    toggle={this.toggleConfirmation}
                    onSubmit={this.onSubmit}
                    submitText="Yes"
                    cancelText="No">
                    Are you sure that you want to refund <b>{formatCurrency(data.amount)}</b>?
                </Confirmation>
                <FormGroup className="summary">
                    {this.renderSummary(data.amount || 0)}
                    <button type="submit" disabled={submitDisabled} id="refund-shipping-costs-submit-button">
                        Submit
                    </button>
                </FormGroup>
            </Form>
        )
    }

    private handleSubmit(event: FormEvent) {
        event.preventDefault()
        this.toggleConfirmation()
    }

    private toggleConfirmation() {
        this.setState({ showConfirmation: !this.state.showConfirmation })
    }

    private calculateRefund() {
        let refundAmount = refundCalculatorService.calculateRefund(100, true, [], this.props.orderDetails)
        this.setState(state => (state.data.amount = refundAmount, state))
    }

    private onSubmit() {
        this.props.onSubmit(this.state.data)
    }

    private renderSummary(amount: number) {
        return (
            <Row className="total">
                <Col sm="4">Total refund</Col>
                <Col id="refund-shipping-costs-total">{formatCurrency(amount)}</Col>
            </Row>
        )
    }
}
