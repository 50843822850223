import React, { ChangeEvent, FormEvent } from 'react'
import { Col, Form, FormGroup, Input, Row } from 'reactstrap'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import { OperationType } from '../../services/OrderManagementService'
import { formatCurrency, validators } from '../../utils'
import Confirmation from './Confirmation'

export interface FormData {
  amount?: number
  operationType?: OperationType
}

interface State {
  data: FormData
  showConfirmation: boolean
}

interface Props {
  onSubmit(form: FormData): void
  valid: boolean
}

export default class OldAmountRefundForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { data: {}, showConfirmation: false }
    this.onSubmit = this.onSubmit.bind(this)
    this.onChangeAmount = this.onChangeAmount.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
  }

  componentDidMount() {
    this.setState({ data: { operationType: OperationType.RefundAmount } })
  }

  render() {
    const { data, showConfirmation } = this.state
    const amountErrorMessage = validators.currency(data.amount)

    return (
      <Form onSubmit={this.handleSubmit}>
        <Confirmation
          isOpen={showConfirmation}
          toggle={this.toggleConfirmation}
          onSubmit={this.onSubmit}
          submitText="Yes"
          cancelText="No">
          Are you sure that you want to refund <b>{formatCurrency(data.amount)}</b>?
        </Confirmation>
        <FormGroup>
          <Input
            invalid={data.amount !== undefined && !!amountErrorMessage}
            onChange={this.onChangeAmount}
            id="refund-amount-input"
          />
          <FormFeedback>{amountErrorMessage}</FormFeedback>
        </FormGroup>
        <FormGroup className="summary">
          {this.renderSummary(data.amount || 0)}
          <button type="submit" disabled={!!amountErrorMessage || !this.props.valid} id="refund-amount-submit-button">
            Submit
          </button>
        </FormGroup>
      </Form>
    )
  }

  private handleSubmit(event: FormEvent) {
    event.preventDefault()
    this.toggleConfirmation()
  }

  private toggleConfirmation() {
    this.setState({ showConfirmation: !this.state.showConfirmation })
  }

  private onChangeAmount(event: ChangeEvent<{ value: string }>) {
    this.setState({ data: { amount: Number(event.target.value) } })
  }

  private onSubmit() {
    this.props.onSubmit(this.state.data)
  }

  private renderSummary(amount: number) {
    return (
      <Row className="total">
        <Col sm="4">Total refund</Col>
        <Col id="refund-amount-total">{formatCurrency(amount)}</Col>
      </Row>
    )
  }
}
