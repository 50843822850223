import _ from 'lodash'
import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import OrderStructure from './OrderStructure'
import { OperationType, ProductionOrderDetails, ProductWithValue } from '../../services/OrderManagementService'
import { OrderValueRecord, RefundCalculatorServiceInstance as refundCalculatorService } from '../../services/RefundCalculatorService'
import * as OrderTypes from '../../api/order'
import Checkbox from './Checkbox'


interface OrderRefundFormState {
  products: ProductWithValue[]
  includeShippingCosts: boolean
}

interface Props {
  onChangeRefund(orderRefund: OrderValueRecord): void
  orderDetails: OrderTypes.OrderDetails
  remainingRefundRecord: OrderValueRecord
}

export default class OrderRefundForm extends React.Component<Props, OrderRefundFormState> {
  constructor(props: Props) {
    super(props)

    this.state = {
      products: [],
      includeShippingCosts: false,
    }

    this.handleProductsChange = this.handleProductsChange.bind(this)
    this.handleShippingCosts = this.handleShippingCosts.bind(this)
  }

  render() {
    const { includeShippingCosts } = this.state

    return (
      <>
        <OrderStructure
          data={this.props.orderDetails}
          handleChange={this.handleProductsChange}
          operation={OperationType.RefundOrder}
        />
        <FormGroup>
          <Checkbox id="includeShippingCosts" checked={includeShippingCosts} onChange={this.handleShippingCosts} />
          <Label for="includeShippingCosts" className="ml-4">
            Include shipping costs
          </Label>
        </FormGroup>
      </>
    )
  }

  private handleProductsChange(products: Array<ProductWithValue>, productionOrders: Array<ProductionOrderDetails>) {
    this.setState(
      {
        products: products,
      },
      () => this.calculateRefund()
    )
  }

  private handleShippingCosts(event: any) {
    this.setState(
      {
        includeShippingCosts: event.target.checked,
      },
      () => this.calculateRefund()
    )
  }

  private calculateRefund() {
    let orderRefund = refundCalculatorService.calculatePercentageRefund(
      100,
      this.state.includeShippingCosts,
      this.state.products,
      this.props.remainingRefundRecord
    )
    this.props.onChangeRefund(orderRefund)
  }
}
